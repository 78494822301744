import 'selectize/dist/js/selectize.min.js';
 var COUNTRIES = [
{name:"Afghanistan", abbr:"AF", id:"/afghanistan"},
{name:"Albania", abbr:"AL", id:"/albania"},
{name:"Algeria", abbr:"DZ", id:"/algeria"},
{name:"Andorra", abbr:"AD", id:"/andorra"},
{name:"Angola", abbr:"AO", id:"/angola"},
{name:"Antigua and Barbuda", abbr:"AG", id:"/antigua-and-barbuda"},
{name:"Argentina", abbr:"AR", id:"/argentina"},
{name:"Armenia", abbr:"AM", id:"/armenia"},
{name:"Australia", abbr:"AU", id:"/australia"},
{name:"Austria", abbr:"AT", id:"/austria"},
{name:"Azerbaijan", abbr:"AZ", id:"/azerbaijan"},
{name:"Bahamas", abbr:"BS", id:"/bahamas"},
{name:"Bahrain", abbr:"BH", id:"/bahrain"},
{name:"Bangladesh", abbr:"BD", id:"/bangladesh"},
{name:"Barbados", abbr:"BB", id:"/barbados"},
{name:"Belarus", abbr:"BY", id:"/belarus"},
{name:"Belgium", abbr:"BE", id:"/belgium"},
{name:"Belize", abbr:"BZ", id:"/belize"},
{name:"Benin", abbr:"BJ", id:"/benin"},
{name:"Bhutan", abbr:"BT", id:"/bhutan"},
{name:"Bolivia", abbr:"BO", id:"/bolivia"},
{name:"Bosnia and Herzegovina", abbr:"BA", id:"/bosnia-and-herzegovina"},
{name:"Botswana", abbr:"BW", id:"/botswana"},
{name:"Brazil", abbr:"BR", id:"/brazil"},
{name:"Brunei", abbr:"BN", id:"/brunei"},
{name:"Bulgaria", abbr:"BG", id:"/bulgaria"},
{name:"Burkina Faso", abbr:"BF", id:"/burkina-faso"},
{name:"Burundi", abbr:"BI", id:"/burundi"},
{name:"Cambodia", abbr:"KH", id:"/cambodia"},
{name:"Cameroon", abbr:"CM", id:"/cameroon"},
{name:"Canada", abbr:"CA", id:"/canada"},
{name:"Cape Verde", abbr:"CV", id:"/cape-verde"},
{name:"The Central African Republic", abbr:"CF", id:"/the-central-african-republic"},
{name:"Chad", abbr:"TD", id:"/chad"},
{name:"Chile", abbr:"CL", id:"/chile"},
// {name:"China", abbr:"CN", id:"/china"},
{name:"Colombia", abbr:"CO", id:"/colombia"},
{name:"Comoros", abbr:"KM", id:"/comoros"},
{name:"Cook Islands", abbr:"CK", id:"/cook-islands"},
{name:"Costa Rica", abbr:"CR", id:"/costa-rica"},
{name:"Cote d'Ivoire", abbr:"CI", id:"/cote-d-ivoire"},
{name:"Croatia", abbr:"HR", id:"/croatia"},
{name:"Cyprus", abbr:"CY", id:"/cyprus"},
{name:"The Czech Republic", abbr:"CZ", id:"/the-czech-republic"},
{name:"The Democratic Republic of the Congo", abbr:"CD", id:"/the-democratic-republic-of-the-congo"},
{name:"Denmark", abbr:"DK", id:"/denmark"},
{name:"Djibouti", abbr:"DJ", id:"/djibouti"},
{name:"Dominica", abbr:"DM", id:"/dominica"},
{name:"The Dominican Republic", abbr:"DO", id:"/the-dominican-republic"},
{name:"Dubai", abbr:"AE", id:"/dubai"},
{name:"Timor-Leste", abbr:"TL", id:"/timor-leste"},
{name:"Ecuador", abbr:"EC", id:"/ecuador"},
{name:"Egypt", abbr:"EG", id:"/egypt"},
{name:"El Salvador", abbr:"SV", id:"/el-salvador"},
{name:"Equatorial Guinea", abbr:"GQ", id:"/equatorial-guinea"},
{name:"Eritrea", abbr:"ER", id:"/eritrea"},
{name:"Estonia", abbr:"EE", id:"/estonia"},
{name:"Ethiopia", abbr:"ET", id:"ethiopia"},
{name:"Fiji", abbr:"FJ", id:"/fiji"},
{name:"Finland", abbr:"FI", id:"/finland"},
{name:"France", abbr:"FR", id:"/france"},
{name:"Gabon", abbr:"GA", id:"/gabon"},
{name:"The Gambia", abbr:"GM", id:"/the-gambia"},
{name:"Georgia", abbr:"GE", id:"/georgia"},
{name:"Germany", abbr:"DE", id:"/germany"},
{name:"Ghana", abbr:"GH", id:"/ghana"},
{name:"Greece", abbr:"GR", id:"/greece"},
{name:"Grenada", abbr:"GD", id:"/grenada"},
{name:"Guatemala", abbr:"GT", id:"/guatemala"},
{name:"Guinea", abbr:"GN", id:"/guinea"},
{name:"Guinea-Bissau", abbr:"GW", id:"guinea-bissau"},
{name:"Guyana", abbr:"GY", id:"/guyana"},
{name:"Haiti", abbr:"HT", id:"/haiti"},
{name:"Honduras", abbr:"HN", id:"/honduras"},
{name:"Hong Kong", abbr:"HK", id:"/hong-kong"},
{name:"Hungary", abbr:"HU", id:"/hungary"},
{name:"Iceland", abbr:"IS", id:"/iceland"},
{name:"India", abbr:"IN", id:"/india"},
{name:"Indonesia", abbr:"ID", id:"/indonesia"},
{name:"Iraq", abbr:"IQ", id:"/iraq"},
{name:"Ireland", abbr:"IE", id:"/ireland"},
{name:"Israel", abbr:"IL", id:"/israel"},
{name:"Italy", abbr:"IT", id:"/italy"},
{name:"Jamaica", abbr:"JM", id:"/jamaica"},
{name:"Japan", abbr:"JP", id:"/japan"},
{name:"Jordan", abbr:"JO", id:"/jordan"},
{name:"Kazakhstan", abbr:"KZ", id:"/kazakhstan"},
{name:"Kenya", abbr:"KE", id:"/kenya"},
{name:"Kiribati", abbr:"KI", id:"/kiribati"},
{name:"Kosovo", abbr:"XK", id:"/kosovo"},
{name:"Kuwait", abbr:"KW", id:"/kuwait"},
{name:"Kyrgyzstan", abbr:"KG", id:"/kyrgyzstan"},
{name:"Laos", abbr:"LA", id:"/laos"},
{name:"Latvia", abbr:"LV", id:"/latvia"},
{name:"Lebanon", abbr:"LB", id:"/lebanon"},
{name:"Lesotho", abbr:"LS", id:"/lesotho"},
{name:"Liberia", abbr:"LR", id:"/liberia"},
{name:"Libya", abbr:"LY", id:"/libya"},
{name:"Liechtenstein", abbr:"LI", id:"/liechtenstein"},
{name:"Lithuania", abbr:"LT", id:"/lithuania"},
{name:"Luxembourg", abbr:"LU", id:"/luxembourg"},
{name:"Macedonia", abbr:"MK", id:"/macedonia"},
{name:"Madagascar", abbr:"MG", id:"/madagascar"},
{name:"Malawi", abbr:"MW", id:"/malawi"},
{name:"Malaysia", abbr:"MY", id:"/malaysia"},
{name:"Maldives", abbr:"MV", id:"/maldives"},
{name:"Mali", abbr:"ML", id:"/mali"},
{name:"Malta", abbr:"MT", id:"/malta"},
{name:"The Marshall Islands", abbr:"MH", id:"/the-marshall-islands"},
{name:"Mauritania", abbr:"MR", id:"/mauritania"},
{name:"Mauritius", abbr:"MU", id:"/mauritius"},
{name:"Mexico", abbr:"MX", id:"/mexico"},
{name:"Micronesia", abbr:"FM", id:"/micronesia"},
{name:"Moldova", abbr:"MD", id:"/moldova"},
{name:"Monaco", abbr:"MC", id:"/monaco"},
{name:"Mongolia", abbr:"MN", id:"/mongolia"},
{name:"Montenegro", abbr:"ME", id:"/montenegro"},
{name:"Morocco", abbr:"MA", id:"/morocco"},
{name:"Mozambique", abbr:"MZ", id:"/mozambique"},
{name:"Myanmar", abbr:"MM", id:"/myanmar"},
{name:"Namibia", abbr:"NA", id:"/namibia"},
{name:"Nauru", abbr:"NR", id:"/nauru"},
{name:"Nepal", abbr:"NP", id:"/nepal"},
{name:"The Netherlands", abbr:"NL", id:"/the-netherlands"},
{name:"New Zealand", abbr:"NZ", id:"/new-zealand"},
{name:"Nicaragua", abbr:"NI", id:"/nicaragua"},
{name:"Niger", abbr:"NE", id:"/niger"},
{name:"Nigeria", abbr:"NG", id:"/nigeria"},
{name:"Niue", abbr:"NU", id:"/niue"},
{name:"Norway", abbr:"NO", id:"/norway"},
{name:"Oman", abbr:"OM", id:"/oman"},
{name:"Pakistan", abbr:"PK", id:"/pakistan"},
{name:"Palau", abbr:"PW", id:"/palau"},
{name:"Panama", abbr:"PA", id:"/panama"},
{name:"Papua New Guinea", abbr:"PG", id:"/papua-new-guinea"},
{name:"Paraguay", abbr:"PY", id:"/paraguay"},
{name:"Peru", abbr:"PE", id:"/peru"},
{name:"The Philippines", abbr:"PH", id:"/the-philippines"},
{name:"Poland", abbr:"PL", id:"/poland"},
{name:"Portugal", abbr:"PT", id:"/portugal"},
{name:"Qatar", abbr:"QA", id:"/qatar"},
{name:"Romania", abbr:"RO", id:"/romania"},
{name:"Russia", abbr:"RU", id:"/russia"},
{name:"Rwanda", abbr:"RW", id:"/rwanda"},
{name:"Saint Kitts and Nevis", abbr:"KN", id:"/"},
{name:"Saint Lucia", abbr:"LC", id:"/saint-kitts-and-nevis"},
{name:"Saint Vincent and the Grenadines", abbr:"VC", id:"/saint-vincent-and-the-grenadines"},
{name:"Samoa", abbr:"WS", id:"/samoa"},
{name:"San Marino", abbr:"SM", id:"/san-marino"},
{name:"Sao Tome and Principe", abbr:"ST", id:"/sao-tome-and-principe"},
{name:"Saudi Arabia", abbr:"SA", id:"/saudi-arabia"},
{name:"Senegal", abbr:"SN", id:"/senegal"},
{name:"Serbia", abbr:"RS", id:"/serbia"},
{name:"Seychelles", abbr:"SC", id:"/seychelles"},
{name:"Sierra Leone", abbr:"SL", id:"/sierra-leone"},
{name:"Singapore", abbr:"SG", id:"/singapore"},
{name:"Slovakia", abbr:"SK", id:"/slovakia"},
{name:"Slovenia", abbr:"SI", id:"/slovenia"},
{name:"The Solomon Islands", abbr:"SB", id:"/the-solomon-islands"},
{name:"Somalia", abbr:"SO", id:"/somalia"},
{name:"South Africa", abbr:"ZA", id:"/south-africa"},
{name:"South Korea", abbr:"KR", id:"/south-korea"},
{name:"South Sudan", abbr:"SS", id:"/south-sudan"},
{name:"Spain", abbr:"ES", id:"/spain"},
{name:"Sri Lanka", abbr:"LK", id:"/sri-lanka"},
{name:"Sudan", abbr:"SD", id:"/sudan"},
{name:"Suriname", abbr:"SR", id:"/suriname"},
{name:"Swaziland", abbr:"SZ", id:"/swaziland"},
{name:"Sweden", abbr:"SE", id:"/sweden"},
{name:"Switzerland", abbr:"CH", id:"/switzerland"},
{name:"Syria", abbr:"SY", id:"/syria"},
{name:"Taiwan", abbr:"TW", id:"/taiwan"},
{name:"Tajikistan", abbr:"TJ", id:"/tajikistan"},
{name:"Tanzania", abbr:"TZ", id:"/tanzania"},
{name:"Thailand", abbr:"TH", id:"/thailand"},
{name:"Togo", abbr:"TG", id:"/togo"},
{name:"Tonga", abbr:"TO", id:"/tonga"},
{name:"Trinidad and Tobago", abbr:"TT", id:"/trinidad-and-tobago"},
{name:"Tunisia", abbr:"TN", id:"/tunisia"},
{name:"Turkey", abbr:"TR", id:"/turkey"},
{name:"Turkmenistan", abbr:"TM", id:"/turkmenistan"},
{name:"Tuvalu", abbr:"TV", id:"/tuvalu"},
{name:"Uganda", abbr:"UG", id:"/uganda"},
{name:"Ukraine", abbr:"UA", id:"/ukraine"},
{name:"The United Arab Emirates (UAE)", abbr:"AE", id:"/the-united-arab-emirates"},
{name:"The United Kingdom (UK)", abbr:"GB", id:"/the-united-kingdom"},
{name:"The United States (USA)", abbr:"US", id:"/the-united-states"},
{name:"Uruguay", abbr:"UY", id:"/uruguay"},
{name:"Uzbekistan", abbr:"UZ", id:"/uzbekistan"},
{name:"Vanuatu", abbr:"VU", id:"/vanuatu"},
{name:"The Vatican City", abbr:"VA", id:"/the-vatican-city"},
{name:"Venezuela", abbr:"VE", id:"/venezuela"},
{name:"Vietnam", abbr:"VN", id:"/vietnam"},
{name:"Western Sahara", abbr:"EH", id:"/western-sahara"},
{name:"Yemen", abbr:"YE", id:"/yemen"},
{name:"Zambia", abbr:"ZM", id:"/zambia"},
{name:"Zimbabwe", abbr:"ZW", id:"/zimbabwe"}
]

$(document).ready(function(){
  $("select").on("change", function (e) {
    window.open(this.value, "_self");
  });


  $('#buy-bitcoin-main').selectize({
    sortField: 'text',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    placeholder: 'Select a country',
    plugins: ['remove_button'],
    options: COUNTRIES,
    render: {
      option: function(item, escape) {
          return `<div class="option-select"><img src='https://bitrawr.s3.eu-central-1.amazonaws.com/assets/country-flags/${escape(item.abbr.toLowerCase())}.svg' class="lazyload flag-icon" alt="${item.name}" width="25px" height="auto">` + escape(item.name) + "</div>";
      }
    }
  });
})
